.img-1 {
  background-image: url('https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/production-studio.jpg');
  /*background-image: url('https://img.freepik.com/free-photo/multiracial-group-young-creative-people-smart-casual-wear-discussing-business-brainstorming-meeting-ideas-mobile-application-software-design-project-modern-office_7861-3067.jpg?w=2080&t=st=1673946473~exp=1673947073~hmac=3988fa13374210aad04fe6d4974f28aa68de9646863919c6d0f9dc23671de2c6');*/
  min-height: 70vh;
/*  The first picture will be larger.  */
}
.img-1{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
/*  This is what makes the effect work. So that when we scroll the image, it retains its min-height with its attatchment fixed  */
  filter: opacity(2) brightness(0.9);
  z-index: 10;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.section p {
  position: relative;
  top: 12px;
  font-size: 18px;
  width: 500px;
}

.section-1 {
  position: relative;
  z-index: 100 !important;
  top: 150px;
  color: #ffffff;
  font-size: 135%;
}

.section-1 h1 {
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
  /*background-color: rgba(243, 243, 243, 0.63);*/
}
